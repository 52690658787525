import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Cart } from '@shared/models/cart';
import { CartConfig } from '@shared/models/cart-config';
import { ProductDiscount } from '@shared/models/product-discount';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartsApiService {
  constructor(private apiService: ApiService) {}

  show(cart_id: string): Observable<{ data: Cart }> {
    return this.apiService.get(`v1/carts/${cart_id}`);
  }

  create(cartConfig: CartConfig): Observable<{ data: Cart }> {
    return this.apiService.post('v1/carts', { data: cartConfig });
  }

  auto_apply_product_discounts(
    group_id: string,
    cart_id?: string,
    cart_item_id?: string
  ): Observable<{ data: ProductDiscount[] }> {
    return this.apiService.get(`v1/carts/auto_apply_product_discounts`, {
      group_id: group_id,
      cart_id: cart_id,
      cart_item_id: cart_item_id,
    });
  }

  create_quotes(
    cart_id: string,
    update_types: string[],
    send_email: boolean,
    quote_reference?: string,
    skip_dx_quote?: boolean,
    expiration_date?: string,
    additional_email_addresses?: string[]
  ): Observable<{ data: { quote_ids: number[] } }> {
    return this.apiService.post(`v1/carts/${cart_id}/create_quotes`, {
      data: {
        update_types: update_types,
        send_email: send_email,
        quote_reference: quote_reference,
        skip_dx_quote: skip_dx_quote,
        expiration_date: expiration_date,
        additional_email_addresses: additional_email_addresses,
      },
    });
  }

  create_order(
    cart_id: string,
    update_type: string,
    order_reference?: string,
    expiration_date?: string
  ): Observable<{ data: { quote_id: number } }> {
    return this.apiService.post(`v1/carts/${cart_id}/create_order`, {
      data: {
        update_type: update_type,
        order_reference: order_reference,
        expiration_date: expiration_date,
      },
    });
  }
}
