import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { Product } from '@shared/models/product';
import { loadProducts, loadProductsFailure, loadProductsSuccess } from '../actions/load-products.actions';

export const featureKey = 'products';

export interface State extends EntityState<Product> {
  loadingState: LoadingState;
}

export const productAdapter = createEntityAdapter<Product>({
  selectId: (product: Product) => product.attributes.code,
  sortComparer: (a: Product, b: Product) => (a.attributes.position > b.attributes.position ? 1 : -1),
});

export const initialState: State = productAdapter.getInitialState({
  loadingState: 'not_loading',
});

export const productsReducer = createReducer(
  initialState,
  mutableOn(loadProducts, (state) => {
    state.loadingState = 'loading';
  }),
  on(loadProductsSuccess, (state, { data }): State => {
    return productAdapter.setAll(data, {
      ...state,
      loadingState: 'loaded',
    });
  }),
  mutableOn(loadProductsFailure, (state) => {
    state.loadingState = 'not_loading';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return productsReducer(state, action);
}

export const productsFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectAll, selectEntities } = productAdapter.getSelectors(productsFeatureSelector);

export const selectAllProducts = selectAll;

export const selectProductsLoadingState = createSelector(productsFeatureSelector, (state: State) => state.loadingState);

export const selectOfferedProducts = createSelector(selectAllProducts, (products) =>
  products.filter((product) => product.attributes.offered)
);

export const selectProductByCode = (code: string) => createSelector(selectEntities, (entities) => entities[code]);

export const selectProductsByCode = (codes: string[]) =>
  createSelector(selectAllProducts, (products) =>
    products.filter((product) => codes.includes(product.attributes.code))
  );
