import { createAction, props } from '@ngrx/store';
import { ProductDiscount } from '@shared/models/product-discount';

export const loadAutoApplyProductDiscounts = createAction('[Cart] Load Apply Product Discounts');

export const loadAutoApplyProductDiscountsSuccess = createAction(
  '[Cart] Load Apply Product Discounts Success',
  props<{ data: ProductDiscount[] }>()
);

export const loadAutoApplyProductDiscountsFailure = createAction(
  '[Cart] Load Apply Product Discounts Failure',
  props<{ error: any }>()
);
