import { setGroupFilters } from './../actions/set-group-filters.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { LoadingState } from '@shared/types/loading-state';
import { Observable } from 'rxjs';
import { loadCustomers } from '../actions/load-customers.actions';
import { loadCustomer } from '../actions/load-customer.actions';
import * as fromCustomers from '../reducers/customers.reducer';
import { selectAddCustomer } from '../actions/select-add-customer.actions';
import { createCustomer } from '../actions/create-customer.actions';
import { updateCustomer } from '../actions/update-customer.actions';
import { Group } from '@shared/models/group';
import { createDxCustomerId } from '../actions/create-dx-customer-id.actions';
import { createDxResellerId } from '../actions/create-dx-reseller-id.actions';
import { resendEmailConfirmation } from '../../shared/actions/resend-email-confirmation.actions';
import { Contact } from '@shared/models/contact';
import { GroupFilters } from '@shared/models/group-filters';
@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private store: Store) {}

  loadCustomers(paginationState: PaginationState, sorting: Sorting, filters: any) {
    this.store.dispatch(
      loadCustomers({
        paginationState: paginationState,
        sorting: sorting,
        filters: filters,
      })
    );
  }

  loadCustomer(groupId: string) {
    this.store.dispatch(loadCustomer({ groupId: groupId }));
  }

  createCustomer(group: Partial<Group>) {
    this.store.dispatch(createCustomer({ group: group }));
  }

  updateCustomer(group: Partial<Group>, updateAllCustomers?: boolean) {
    this.store.dispatch(updateCustomer({ group: group, updateAllCustomers: updateAllCustomers }));
  }

  createDxCustomerId(group_id: string) {
    this.store.dispatch(createDxCustomerId({ group_id: group_id }));
  }

  createDxResellerId(group_id: string) {
    this.store.dispatch(createDxResellerId({ group_id: group_id }));
  }

  resendEmailConfirmation(group_id: string, new_email: string, contact_type: string) {
    this.store.dispatch(
      resendEmailConfirmation({ group_id: group_id, new_email: new_email, contact_type: contact_type })
    );
  }

  selectAddCustomer(groupId: string, groupName?: string, partnerContact?: Contact) {
    this.store.dispatch(selectAddCustomer({ groupId, groupName, partnerContact }));
  }

  setGroupFilters(filters: GroupFilters) {
    this.store.dispatch(setGroupFilters({ filters: filters }));
  }

  getCustomerById(groupId: string): Observable<Group | undefined> {
    return this.store.select(fromCustomers.selectCustomerById(groupId));
  }

  getCustomersLoading(): Observable<LoadingState> {
    return this.store.select(fromCustomers.selectCustomersLoadingState);
  }
  getCustomersLoadingText(): Observable<string | undefined> {
    return this.store.select(fromCustomers.selectCustomersLoadingText);
  }

  getCustomers(): Observable<Group[]> {
    return this.store.select(fromCustomers.selectAllGroups);
  }

  getPaginationState(): Observable<PaginationState> {
    return this.store.select(fromCustomers.selectCustomersPaginationState);
  }

  getAddCustomerAttributes(): Observable<{
    groupId: string | undefined;
    groupName: string | undefined;
    partnerContact: Contact | undefined;
  }> {
    return this.store.select(fromCustomers.selectAddCustomerAttributes);
  }

  getGroupFilters(): Observable<GroupFilters> {
    return this.store.select(fromCustomers.selectGroupFilters);
  }
}
