import { createAction, props } from '@ngrx/store';

export const downloadInvoice = createAction(
  '[Accounts] Download Invoice',
  props<{
    invoice_id: number;
    options?: {
      email?: boolean;
      email_address?: string;
      preview?: boolean;
      regenerate?: boolean;
    };
  }>()
);

export const downloadInvoiceSuccess = createAction(
  '[Accounts] Download Invoice Success',
  props<{
    bucket?: string;
    presigned?: string;
    filename?: string;
    path?: string;
    generating?: boolean;
    reattempt_time?: number;
  }>()
);

export const downloadInvoiceFailure = createAction('[Accounts] Download Invoice Failure', props<{ error: any }>());

export const retryDownloadInvoice = createAction(
  '[Accounts]  Retry Download Invoice',
  props<{
    invoice_id: number;
    options?: {
      email?: boolean;
      email_address?: string;
      preview?: boolean;
      regenerate?: boolean;
    };
  }>()
);
