import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { ProductDiscount } from '@shared/models/product-discount';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import {
  loadAutoApplyProductDiscounts,
  loadAutoApplyProductDiscountsFailure,
  loadAutoApplyProductDiscountsSuccess,
} from '../actions/load-auto-apply-product-discounts.actions';
import { setCartConfig } from '../actions/set-cart-config.actions';

export const featureKey = 'product-discounts';

export const productDiscountsAdapter = createEntityAdapter<ProductDiscount>({
  selectId: (productDiscount: ProductDiscount) => productDiscount.product_code,
});

export interface State extends EntityState<ProductDiscount> {
  loadingState: LoadingState;
}

export const initialState: State = productDiscountsAdapter.getInitialState({
  loadingState: 'not_loading',
});

export const productDiscountsReducer = createReducer(
  initialState,
  mutableOn(loadAutoApplyProductDiscounts, (state) => {
    state.loadingState = 'loading';
  }),
  on(setCartConfig, (state, { clearCart }): State => {
    if (clearCart) {
      return productDiscountsAdapter.removeAll({
        ...state,
        loadingState: 'not_loading',
      });
    } else {
      return state;
    }
  }),
  on(loadAutoApplyProductDiscountsSuccess, (state, { data }): State => {
    return productDiscountsAdapter.setAll(data, {
      ...state,
      loadingState: 'loaded',
    });
  }),
  mutableOn(loadAutoApplyProductDiscountsFailure, (state) => {
    state.loadingState = 'not_loading';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return productDiscountsReducer(state, action);
}

export const productDiscountsFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = productDiscountsAdapter.getSelectors(
  productDiscountsFeatureSelector
);

export const selectAllProductDiscounts = selectAll;

export const selectProductDiscountsLoadingState = createSelector(
  productDiscountsFeatureSelector,
  (state: State) => state.loadingState
);

export const selectProductDiscountsByProductCode = (productCode: string) =>
  createSelector(selectEntities, (entities) => (productCode == undefined ? undefined : entities[productCode]));
