import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadOrders } from '@features/orders/actions/load-orders.actions';
import * as fromOrders from '@features/orders/reducers/orders.reducer';
import { OrderFilters } from '@shared/models/order-filters';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { Observable, take } from 'rxjs';
import { LoadingState } from '@shared/types/loading-state';
import { Order } from '@shared/models/order';
import { OrderHistory } from '@shared/models/order';
import { downloadOrEmailLicenses } from '../actions/download-or-email-Licenses.actions';
import { downloadOrdersCsv } from '../actions/download-orders-csv.actions';
import { loadOrder } from '../actions/load-order.actions';
import { setSelectedOrderId } from '../actions/set-selected-order-id.actions';
import { SessionService } from '@shared/services/session.service';
import moment from 'moment';
import { cancelOrder } from '../shared/actions/cancel-order.actions';
import { reactivateOrder } from '../shared/actions/reactivate-order.actions';
import { suspendOrder } from '../shared/actions/suspend-order.actions';
import { unsuspendOrder } from '../shared/actions/unsuspend-order.actions';
import { downloadInvoice } from '@features/accounts/shared/actions/download-invoice.actions';
import { setOrderFilters } from '../actions/set-order-filters.actions';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private store: Store, private sessionService: SessionService) {}

  loadOrders(filters: OrderFilters, paginationState: PaginationState, sorting: Sorting, expiries?: boolean) {
    this.store.dispatch(loadOrders({ filters, paginationState, sorting, expiries }));
  }

  loadOrder(orderId: number) {
    this.store.dispatch(loadOrder({ orderId, requiresReload: false }));
  }

  viewLicensesPdf(orderId: number, regenerate = false) {
    this.store.dispatch(downloadOrEmailLicenses({ order_id: orderId, options: { regenerate: regenerate } }));
  }

  emailLicensesPdf(orderId: number, emailAddress: string) {
    this.store.dispatch(
      downloadOrEmailLicenses({ order_id: orderId, options: { email: true, email_address: emailAddress } })
    );
  }

  cancelOrder(orderId: number, reason: string, refundPayment?: boolean, forUnprocessedQuote = false, quoteId?: number) {
    this.store.dispatch(
      cancelOrder({
        orderId: orderId,
        reason: reason,
        forUnprocessedQuote: forUnprocessedQuote,
        quoteId: quoteId,
        refundPayment: refundPayment,
      })
    );
  }

  reactivateOrder(orderId: number, reactivate = false) {
    this.store.dispatch(reactivateOrder({ orderId: orderId, reactivate: reactivate }));
  }

  suspendOrder(orderId: number, reason: string) {
    this.store.dispatch(suspendOrder({ orderId: orderId, reason: reason }));
  }

  unsuspendOrder(orderId: number, reason: string) {
    this.store.dispatch(unsuspendOrder({ orderId: orderId, reason: reason }));
  }

  downloadInvoicePdf(invoice_id: number, regenerate = false) {
    this.store.dispatch(
      downloadInvoice({ invoice_id: invoice_id, options: { preview: false, regenerate: regenerate } })
    );
  }

  setOrderFilters(filters: OrderFilters | undefined) {
    this.store.dispatch(setOrderFilters({ filters }));
  }

  downloadOrdersCsv() {
    return this.store.dispatch(downloadOrdersCsv());
  }

  getOrdersList(): Observable<Order[]> {
    return this.store.select(fromOrders.selectAllOrders);
  }

  getOrderDetailsById(orderId: number): Observable<Order | undefined> {
    return this.store.select(fromOrders.selectOrderById(orderId));
  }

  getOrderHistorySortedByDate(): Observable<OrderHistory[] | undefined> {
    return this.store.select(fromOrders.orderHistorySortedByDate);
  }

  getOrdersLoadingState(): Observable<LoadingState> {
    return this.store.select(fromOrders.selectOrdersLoadingState);
  }

  getOrdersLoadingText(): Observable<string | undefined> {
    return this.store.select(fromOrders.selectOrdersLoadingText);
  }

  getOrdersPaginationState(): Observable<PaginationState> {
    return this.store.select(fromOrders.selectOrdersPaginationState);
  }

  getOrderFilters(): Observable<OrderFilters | undefined> {
    return this.store.select(fromOrders.selectOrderFilters);
  }

  setSelectedOrderId(orderId: number): void {
    this.store.dispatch(setSelectedOrderId({ orderId }));
  }

  getSelectedOrder(): Observable<Order | undefined> {
    return this.store.select(fromOrders.selectSelectedOrder);
  }

  getCanCancelSelectedOrder(): Observable<boolean> {
    return this.store.select(fromOrders.selectCanCancelSelectedOrder);
  }

  getCanReactivateSelectedOrder(): Observable<boolean> {
    return this.store.select(fromOrders.selectCanReactivateSelectedOrder);
  }

  getSuspendOrUnsuspendSelectedOrder(): Observable<string> {
    return this.store.select(fromOrders.selectSuspendOrUnsuspendSelectedOrder);
  }

  getOrderStatusToDisplay(order: Order): string | undefined {
    const orderStatus = order.attributes.status;
    const hasActiveLicenses = this.hasActiveLicenses(order);
    if (orderStatus === 'suspended' || orderStatus === 'cancelled') {
      return orderStatus;
    } else if (hasActiveLicenses && orderStatus == 'processed') {
      return 'complete';
    } else if (!hasActiveLicenses && orderStatus == 'processed') {
      return 'inactive';
    } else if (this.isDraftOrder(order)) {
      return 'draft';
    }
    return undefined;
  }

  hasActiveLicenses(order: Order): boolean {
    return order.attributes.licenses?.some((license) => license.status === 'active');
  }

  hasAllLicenseKeys(order: Order): boolean {
    let hasAllLicenseKeys = true;
    order?.attributes?.licenses?.forEach((license) => {
      if (!license.lkey) {
        hasAllLicenseKeys = false;
        return;
      }
    });
    return hasAllLicenseKeys;
  }

  isDraftOrder(order: Order): boolean {
    return order.attributes.status === 'created' && !!order.attributes.ges_order_number;
  }

  canViewInvoicePdf(isSuperUser: boolean, order: Order): boolean {
    return (
      !!order.attributes.invoice &&
      (isSuperUser ||
        (!isSuperUser &&
          !['global_e_store', 'online_store', 'auto_renewal_system'].includes(order.attributes.order_source)))
    );
  }
}
