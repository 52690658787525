<lib-version-update></lib-version-update>
<lib-maintenance></lib-maintenance>
<lib-offline></lib-offline>
<app-loader
  [loadingState]="sessionLoadingState$ | async"
  [helperText]="sessionLoadingText$ | async"
  [top]="0"
  [screenWidth]="true"
></app-loader>
<div
  *ngIf="(sessionLoadingState$ | async) == 'loaded'"
  class="flex flex-row h-full bg-gray-100 text-gray-800 bg-grey-light-concrete"
>
  <div
    *ngIf="(session$ | async)?.email_confirmed == false"
    class="absolute bottom-3 right-3 p-2 z-50 max-w-3xl bg-bright-orange text-white flex justify-between items-center rounded-md"
  >
    <ion-icon name="shield-half-outline" class="m-4 w-10 h-6"></ion-icon>
    <span>
      Your user account is unverified and certain features will be limited. To verify, click on the link emailed to
      <em>{{ (session$ | async)?.pending_email }}</em
      >.
    </span>
    <button
      [disabled]="(confirmEmailLoadingState$ | async) == 'loading'"
      class="btn-default btn-xs border-bright-orange text-bright-orange mr-2 relative min-w-[145px] flex items-center justify-center"
      (click)="resendEmailConfirmation()"
    >
      <app-loader [loadingState]="confirmEmailLoadingState$ | async" [inline]="true" [size]="16"></app-loader>
      {{ (confirmEmailLoadingState$ | async) == 'loading' ? '' : 'Resend Confirmation' }}
    </button>
  </div>
  <div
    *ngIf="
      (session$ | async)?.mfa_enforced && (session$ | async)?.email_confirmed && !(session$ | async)?.mfa_activated
    "
    class="absolute bottom-3 right-3 p-2 z-50 max-w-3xl bg-bright-orange text-white flex justify-between items-center rounded-md"
    id="mfa-enforced-banner"
  >
    <ion-icon name="shield-half-outline" class="m-4 w-10 h-6"></ion-icon>
    <span>
      Multi-Factor Authentication (MFA) is required for full system access. Please setup MFA in your profile settings.
    </span>
    <button
      class="btn-default btn-xs border-bright-orange text-bright-orange mr-2 relative min-w-[145px] flex items-center justify-center"
      [routerLink]="['/settings/profile']"
    >
      Set Up MFA
    </button>
  </div>
  <app-side-nav></app-side-nav>
  <main
    class="main flex flex-col flex-grow h-full overflow-auto transition-all duration-150 ease-in"
    [@routerTransition]="
      isMobileScreenWidth
        ? { value: (currentUrl$ | async), params: { width: '100%' } }
        : { value: (currentUrl$ | async), params: { width: 'calc(100% - 300px)' } }
    "
  >
    <div class="main-content h-full flex flex-col flex-grow">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
