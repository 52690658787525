import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { SessionService } from '@shared/services/session.service';
import { of, switchMap, withLatestFrom } from 'rxjs';

export function mainRouteGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const sessionService: SessionService = inject(SessionService);
    const router: Router = inject(Router);

    return sessionService.getIsSuperUser().pipe(
      withLatestFrom(sessionService.getSessionPermissions(), sessionService.getSession()),
      switchMap(([isSuperUser, permissions, session]) => {
        if (session!.mfa_enforced && !session!.mfa_activated) return of(router.parseUrl('/settings/profile'));
        switch (route.routeConfig?.path) {
          case 'orders-and-quotes': {
            if (permissions?.sales?.orders?.view || permissions?.sales?.quotes?.view) {
              return of(true);
            }
            break;
          }
          case 'cart': {
            if (
              permissions?.sales?.orders?.create ||
              permissions?.sales?.quotes?.create ||
              permissions?.sales?.nfr_licenses?.create ||
              permissions?.sales?.trial_licenses?.create
            ) {
              return of(true);
            }
            break;
          }
          case 'customers': {
            if (permissions?.clients?.view) {
              return of(true);
            }
            break;
          }
          case 'promotions': {
            if (isSuperUser && permissions?.sales?.discounts?.view) {
              return of(true);
            }
            break;
          }
          case 'my-account': {
            if (!isSuperUser && permissions?.accounts?.view) {
              return of(true);
            }
            break;
          }
          case 'accounts': {
            if (isSuperUser && permissions?.accounts?.view) {
              return of(true);
            }
            break;
          }
          case 'partner-applications': {
            if (isSuperUser && permissions?.partner_applications?.view) {
              return of(true);
            }
            break;
          }
          case 'products': {
            if (isSuperUser && permissions?.administration?.products_crud) {
              return of(true);
            }
            break;
          }
          case 'users': {
            if (permissions?.users?.view) {
              return of(true);
            }
            break;
          }
          case 'support': {
            if (!isSuperUser) {
              return of(true);
            }
            break;
          }
        }
        return of(router.parseUrl('/'));
      })
    );
  };
}
