import { Action, createReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { mutableOn } from 'ngrx-etc';
import { createQuotesSuccess } from '../actions/create-quotes.actions';
import { CartConfig } from '@shared/models/cart-config';
import { setCartConfig } from '../actions/set-cart-config.actions';
import { Group } from '@shared/models/group';
import { loadGroup, loadGroupFailure, loadGroupSuccess } from '@shared/actions/load-group.actions';
import { createCart, createCartSuccess } from '../actions/create-cart.actions';
import { loadCartFailure } from '../actions/load-cart.actions';
import { selectCartItem } from '../actions/select-cart-item.actions';
import { removeCartItemSuccess } from '../actions/remove-cart-item.actions';
import { loadQuoteSuccess } from '@features/quotes/shared/actions/load-quote.actions';
import { LoadingState } from '@shared/types/loading-state';
import { updateCustomerSuccess } from '@features/customers/admin/actions/update-customer.actions';
import { CartCustomerValidations } from '@shared/models/cart-customer-validations';

export const featureKey = 'cart-config';

export interface State {
  customer: Group | undefined;
  cartId: string | undefined;
  cartItemId: string | undefined;
  cartConfig: CartConfig | undefined;
  loadingState: LoadingState;
}

export const initialState: State = {
  customer: undefined,
  cartId: undefined,
  cartItemId: undefined,
  cartConfig: undefined,
  loadingState: 'not_loading',
};

export const cartConfigReducer = createReducer(
  initialState,
  mutableOn(createCart, (state, { cartConfig }) => {
    state.cartConfig = cartConfig;
  }),
  mutableOn(createCartSuccess, (state, { cart }) => {
    state.cartId = cart.id;
  }),
  mutableOn(loadQuoteSuccess, createQuotesSuccess, loadCartFailure, (state, {}) => {
    state.cartId = undefined;
    state.cartConfig = undefined;
    state.customer = undefined;
  }),
  mutableOn(setCartConfig, (state, { cartConfig, clearCart }) => {
    state.cartConfig = cartConfig;
    if (clearCart) {
      state.cartId = undefined;
      state.customer = undefined;
    }
  }),
  mutableOn(loadGroupSuccess, (state, { group }) => {
    if (!!state.cartConfig) {
      state.cartConfig!.group_id = group.attributes.id;
      state.customer = group;
    }
  }),
  mutableOn(removeCartItemSuccess, (state, { cart }) => {
    if (state.cartConfig && state.cartConfig?.quote_type !== cart.quote_type) {
      state.cartConfig.quote_type = cart.quote_type;
    }
  }),
  mutableOn(selectCartItem, (state, { cartItemId }) => {
    state.cartItemId = cartItemId;
  }),
  mutableOn(updateCustomerSuccess, (state, { group }) => {
    if (state.cartConfig?.group_id === group.attributes.id) state.customer = group;
  }),
  mutableOn(loadGroup, (state) => {
    state.loadingState = 'loading';
  }),
  mutableOn(loadGroupFailure, (state) => {
    state.loadingState = 'not_loading';
  }),
  mutableOn(loadGroupSuccess, (state) => {
    state.loadingState = 'loaded';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return cartConfigReducer(state, action);
}

export const cartConfigFeatureSelector = createFeatureSelector<State>(featureKey);

export const selectCartConfigLoadingState = createSelector(cartConfigFeatureSelector, (state) => state.loadingState);

export const selectCartId = createSelector(cartConfigFeatureSelector, (state) => state.cartId);

export const selectCartItemId = createSelector(cartConfigFeatureSelector, (state) => state.cartItemId);

export const selectCartConfig = createSelector(cartConfigFeatureSelector, (state) => state.cartConfig);

export const selectCartCustomer = createSelector(cartConfigFeatureSelector, (state) => state.customer);

export const selectCartCustomerValidations = createSelector(cartConfigFeatureSelector, (state) => {
  return {
    primaryEmailSameAsParentGroupEmail:
      !!state.customer &&
      state.customer?.attributes.contacts.primary_contact.email === state.customer?.attributes.parent_group_email,
    primaryEmailSameAsPartnerContactEmail:
      !!state.customer &&
      state.customer?.attributes.contacts.primary_contact.email ===
        state.customer?.attributes.parent_group_partner_email,
    entityTypeIsNotCompany: state.customer?.attributes.group_entity_type !== 1,
    crnInvalid:
      !state.customer?.attributes.dx_company_registration ||
      state.customer?.attributes.dx_company_registration.toLowerCase().includes('not available'),
  } as CartCustomerValidations;
});
