export const environment = {
  production: true,
  environment: 'production',
  company_name: 'ESET Southern Africa',
  api_url: 'https://transact-api.eset.co.za',
  services_api: 'https://services.eset.co.za',
  cookie_scope: '',
  session_domain: '.eset.co.za',
  eset_website: 'https://www.eset.com/za',
  static_url: 'https://static.eset.co.za/',
  content_url: 'https://cdn-uploads.eset.co.za',
  login_url: 'https://login.eset.co.za',
  root_group_id: 'eset',
  currency_symbol: 'R',
  worker_locale: 'south_africa',
  default_country: 'ZA',
  paypal_client_id: '',
  payu_secure_fields_url: 'https://js.paymentsos.com/v3/latest/secure-fields.min.js',
  payu_public_key: 'cb9c1fe0-39c9-4bba-ae8c-95020e9577d6',
  payu_environment: 'live',
  auth_token:
    'eyJhbGciOiJIUzUxMiJ9.eyJhcHBsaWNhdGlvbiI6InRyYW5zYWN0IiwidG9rZW4iOiJaTnVJaW5RWWRQYmlmVzhZR1hQZlkxcFdXIn0.QZwmLizymKXMZnQVhefQznNtRWApASQV5P9IquroZ1ydByJMnVFiBdw4D_JYNZfOSjhVhzZcQby_JuLNOe_9MQ',
  applications: {
    intranet: {
      name: 'Intranet',
    },
    partnerzone: {
      name: 'PartnerZone',
    },
    reporting: {
      name: 'Reporting',
    },
  },
  resources_link: {
    marketing: 'https://marketing.eset.co.za/',
    api: 'https://isp-api-docs.eset.co.za/',
  },
  banking: {
    accountname: '4D Innovations Group (Pty) Ltd.',
    bank: 'FNB',
    branch: '260505 (Tygervalley)',
    accountno: '62376766998',
    swift: 'FIRNZAJJ',
    accounts_email: 'accounts@eset.co.za',
    reference_text: 'Please use your quote number or Client ID as payment reference.',
  },
  contact_details: {
    phone_number_display: '021 659 2000',
    phone_number_actual: '+27216592000',
    partner_email: 'partners@eset.co.za',
    support_email: 'support@eset.co.za',
    accounts_email: 'accounts@eset.co.za',
    support_url_display: 'help.eset.com',
    support_url_actual: 'https://help.eset.com',
  },
};
