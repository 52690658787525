export interface PaginationState {
  page: number;
  per_page: number;
  total_records: number;
  total_pages: number;
}

export const defaultPaginationState: PaginationState = {
  page: 1,
  per_page: 25,
  total_records: 0,
  total_pages: 0,
};
